
<template>
  <div class="openBanner">
    <img :src="data.image" alt="">

<!--    <div class="see-more" @click="toBigMap">-->
<!--      查看地图-->
<!--    </div>-->
  </div>
</template>
<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import {useRoute, useRouter} from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import axios from 'axios'
export default {
  name: "banner-desc",
  setup() {
    const { proxy } = getCurrentInstance();
    const $route = useRoute();
    const $router = useRouter();

    const data = reactive(
      {
        image:$route.query.image
      }
    )
    onMounted(async () => {

    });

    const toBigMap = () => {
      $router.push('/bigMap');
    }
    return {
      data,
      toBigMap,
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


